import React from 'react';
import NoteItem from './NoteItem';

function NotesList({ notes, activeNoteId, onNoteClick, searchQuery, onSearchChange, onSearchSubmit }) {
    return (
        <aside className="aside-lg bg-light lighter with-border-left" id="note-list">
            <section className="vertical_container">
                <header className="header bg-light lighter clearfix with-border-bottom">
                    <div className="input-group m-t-sm">
                        <span className="input-group-addon input-sm">
                            <i className="fa fa-search"></i>
                        </span>
                        <input
                            type="text"
                            className="form-control input-sm"
                            id="search-note"
                            placeholder="Поиск"
                            value={searchQuery}  // связываем поле с состоянием
                            onChange={onSearchChange}  // обрабатываем изменение
                            onKeyDown={onSearchSubmit} // обрабатываем нажатие клавиши
                        />
                    </div>
                </header>
                <section className="scrollable">
                    <div className="wrapper">
                        <ul id="note-items" className="list-group">
                            {notes.map(note => (
                                <NoteItem
                                    key={note.id}
                                    note={note}
                                    isActive={note.id === activeNoteId}
                                    onClick={() => onNoteClick(note.id)}
                                />
                            ))}
                        </ul>
                    </div>
                </section>
            </section>
        </aside>
    );
}

export default NotesList;
