import axios from 'axios';

// Функция для загрузки задач с другого домена
export const fetchTasksFromDomain = async (domain) => {
    try {
        const response = await axios.get(`https://${domain}/api/external/tasks/read.php?external=true`, {
            withCredentials: true,
        });
        return sortTasks(response.data);
    } catch (error) {
        console.error('Ошибка при получении задач с удаленного домена:', error);
        throw error;
    }
};

// Функция сортировки задач
const sortTasks = (tasks) => {
    tasks.sort((a, b) => {
        // Сортировка по сроку выполнения
        if (a.passed && !b.passed) return -1;
        if (b.passed && !a.passed) return 1;

        // Сортировка по закреплению задач
        if (a.pinned && !b.pinned) return -1;
        if (b.pinned && !a.pinned) return 1;

        // Сортировка по дате создания
        return new Date(b.created_at) - new Date(a.created_at);
    });

    return tasks;
};

// Функция для форматирования даты
export const formatDate = (dateStr) => {
    if (!dateStr) return null;
    const date = new Date(dateStr);
    return date.toLocaleDateString();
};

// Функция для определения стиля задачи
export const getTaskStyle = (task) => {
    if (task.status === 1) {
        return { color: '#449e48' }; // Зеленый цвет для выполненных задач
    }

    const dueDate = new Date(task.due_date);
    const currentDate = new Date();

    // Проверка на просрочку
    if (task.due_date && dueDate < currentDate && task.status !== 1) {
        return { color: '#c3362b', fontWeight: 700 }; // Красный цвет и жирный шрифт для просроченных задач
    }

    return {}; // Обычный стиль для остальных задач
};
