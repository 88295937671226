import React, { useState, useEffect } from 'react';
import TimelineHeader from './TimelineHeader';
import TimelineContent from './TimelineContent';
import './timeline.css';

function Timeline() {
    const [selectedProject, setSelectedProject] = useState(localStorage.getItem('selectedProject') || '');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [period, setPeriod] = useState(localStorage.getItem('period') || '1'); // Default to 1 month

    const updateDatesBasedOnPeriod = (selectedPeriod) => {
        const currentDate = new Date();
        let newStartDate;

        switch (selectedPeriod) {
            case '1':
                newStartDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
                break;
            case '3':
                newStartDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
                break;
            case '6':
                newStartDate = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
                break;
            case '12':
                newStartDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));
                break;
            default:
                newStartDate = new Date();
        }

        setStartDate(newStartDate.toISOString().split('T')[0]);
        setEndDate(new Date().toISOString().split('T')[0]);
    };

    useEffect(() => {
        updateDatesBasedOnPeriod(period);
    }, [period]);

    return (
        <div>
            <TimelineHeader
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setPeriod={setPeriod}
            />
            <TimelineContent
                projectId={selectedProject}
                startDate={startDate}
                endDate={endDate}
            />
        </div>
    );
}

export default Timeline;
