import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './ContentArea/Header';
import TaskList from './Tasks/TaskList';
import TaskDetail from './Tasks/taskDetail';
import Footer from './ContentArea/Footer';
import Projects from './Projects/Projects';
import UpcomingReminders from './Reminders/UpcomingReminders';
import {
    fetchTasks,
    fetchProjects,
    addNewTask,
    handleTaskClick,
    handleDoubleClick,
    handleTitleChange,
    handleKeyDown,
    finishEditing,
    toggleDropdown,
    toggleProjectDropdown,
    closeTask,
    toggleDone,
    handleSearch,
    updateTaskDescription,
    assignTaskToProject
} from './taskFunctions';
import {
    toggleDateSeparators,
    toggleUpcomingReminders,
    handlePageChange,
    addNewProject,
    togglePin,
    filterTasksByProject
} from './contentAreaFunctions';

function ContentArea({ showProjects }) {
    // Состояния и использование функций как раньше, но теперь они импортированы из contentAreaFunctions.js
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [projects, setProjects] = useState([]);
    const [upcomingReminders, setUpcomingReminders] = useState([]);
    const [showUpcomingReminders, setShowUpcomingReminders] = useState(
        JSON.parse(localStorage.getItem('showUpcomingReminders')) ?? true
    );
    const [showDateSeparators, setShowDateSeparators] = useState(
        JSON.parse(localStorage.getItem('showDateSeparators')) ?? true
    );
    const [activeTaskIndex, setActiveTaskIndex] = useState(null);
    const [editTaskIndex, setEditTaskIndex] = useState(null);
    const [editTitle, setEditTitle] = useState('');
    const [dropdownOpenIndex, setDropdownOpenIndex] = useState(null);
    const [projectDropdownOpenIndex, setProjectDropdownOpenIndex] = useState(null);
    const [selectedProject, setSelectedProject] = useState(localStorage.getItem('selectedProject') || '');
    const [currentPage, setCurrentPage] = useState(1);
    const tasksPerPage = 10;

    const indexOfLastTask = currentPage * tasksPerPage;
    const indexOfFirstTask = indexOfLastTask - tasksPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstTask, indexOfLastTask);

    useEffect(() => {
        const loadData = async () => {
            if (!showProjects) {
                const loadedProjects = await fetchProjects(setProjects);
                const savedProjectId = localStorage.getItem('selectedProject');
    
                fetchTasks(setTasks, setFilteredTasks, loadedProjects).then(tasks => {
                    if (savedProjectId) {
                        const filteredTasks = tasks.filter(task => task.project_id === parseInt(savedProjectId));
                        setFilteredTasks(filteredTasks);
                    }
                });
    
                axios.get('/api/reminders/read.php')
                    .then(response => {
                        const allReminders = response.data;
                        const upcoming = allReminders.filter(reminder => {
                            const reminderDate = new Date(reminder.reminder_date);
                            const today = new Date();
                            const nextWeek = new Date();
                            nextWeek.setDate(today.getDate() + 7);
                            return reminderDate <= nextWeek;
                        });
                        setUpcomingReminders(upcoming);
                    })
                    .catch(error => {
                        console.error('Error fetching reminders:', error);
                    });
            } else {
                fetchProjects(setProjects);
            }
        };
        loadData();
    }, [showProjects]);
    

    return (
        <>
            <section className="horizontal_container fullwidth" id="taskapp">
                <aside>
                    <section className="vertical_container">
                    <Header 
                        addNewTask={() => addNewTask(setTasks, setFilteredTasks, setSelectedTask, selectedProject, projects)} 
                        addNewProject={() => addNewProject(setProjects)}
                        onSearch={(term) => handleSearch(term, tasks, setFilteredTasks)} 
                        showProjects={showProjects} 
                        toggleDateSeparators={() => toggleDateSeparators(showDateSeparators, setShowDateSeparators, tasks, setFilteredTasks, selectedProject)} 
                        showDateSeparators={showDateSeparators}
                        projects={projects}
                        filterTasksByProject={(projectId) => filterTasksByProject(projectId, tasks, setFilteredTasks, setSelectedProject)}
                    />




                        {!showProjects && (
                            <UpcomingReminders
                                reminders={upcomingReminders}
                                showUpcomingReminders={showUpcomingReminders}
                                toggleUpcomingReminders={() => toggleUpcomingReminders(showUpcomingReminders, setShowUpcomingReminders)}
                            />
                        )}

                        {showProjects ? (
                            <Projects projects={projects} setProjects={setProjects} />
                        ) : (
                            <TaskList
                                tasks={currentTasks}
                                filteredTasks={filteredTasks}
                                setTasks={setTasks}
                                setFilteredTasks={setFilteredTasks}
                                activeTaskIndex={activeTaskIndex}  
                                setActiveTaskIndex={setActiveTaskIndex}  
                                editTaskIndex={editTaskIndex}
                                editTitle={editTitle}
                                dropdownOpenIndex={dropdownOpenIndex}
                                projectDropdownOpenIndex={projectDropdownOpenIndex}
                                handleTaskClick={(e, taskId) => handleTaskClick(e, taskId, currentTasks, setSelectedTask, setActiveTaskIndex)} 
                                handleDoubleClick={(taskId) => handleDoubleClick(taskId, currentTasks, setEditTaskIndex, setEditTitle)}
                                handleTitleChange={(e) => handleTitleChange(e, setEditTitle)}
                                handleKeyDown={(e, taskId) => handleKeyDown(e, taskId, (id) => finishEditing(id, tasks, editTitle, setTasks, setFilteredTasks, setEditTaskIndex, setEditTitle, selectedProject))}
                                finishEditing={(taskId) => finishEditing(taskId, tasks, editTitle, setTasks, setFilteredTasks, setEditTaskIndex, setEditTitle, selectedProject)}
                                toggleDropdown={(e, taskId) => toggleDropdown(e, taskId, dropdownOpenIndex, setDropdownOpenIndex)}
                                toggleProjectDropdown={(e, taskId) => toggleProjectDropdown(e, taskId, projectDropdownOpenIndex, setProjectDropdownOpenIndex)}
                                closeTask={(taskId) => closeTask(taskId, tasks, setTasks, setFilteredTasks, setDropdownOpenIndex, selectedProject)} // Передаем selectedProject
                                toggleDone={(e, taskId) => toggleDone(e, taskId, tasks, filteredTasks, setTasks, setFilteredTasks, selectedProject)}
                                projects={projects}
                                assignTaskToProject={(taskId, projectId) => assignTaskToProject(taskId, projectId, tasks, setTasks, setFilteredTasks, projects, selectedProject)}
                                showDateSeparators={showDateSeparators}
                                togglePin={(e, taskId) => togglePin(e, taskId, tasks, setTasks, setFilteredTasks, selectedProject)}
                                selectedProject={selectedProject} // Добавляем selectedProject
                            />
                        )}
                        <Footer 
                            showProjects={showProjects} 
                            addNewProject={() => addNewProject(setProjects)} 
                            currentPage={currentPage} 
                            setCurrentPage={(pageNumber) => handlePageChange(pageNumber, setCurrentPage, setActiveTaskIndex)} 
                            totalTasks={filteredTasks.length} 
                            tasksPerPage={tasksPerPage}
                        />
                    </section>
                </aside>
                {!showProjects && 
                    <TaskDetail 
                        selectedTask={selectedTask} 
                        updateTaskDescription={(taskId, description) => updateTaskDescription(taskId, description, tasks, setTasks, setFilteredTasks, selectedProject)} 
                        selectedProject={selectedProject}
                    />
                }
            </section>
        </>
    );
}

export default ContentArea;
