import React, { useEffect, useState } from 'react';
import axios from 'axios';

function NoteItem({ note, isActive, onClick }) {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(note.project_id || '');

    useEffect(() => {
        axios.get('/api/projects/read.php')
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
    }, []);

    useEffect(() => {
        setSelectedProject(note.project_id || '');
    }, [note.project_id]);

    const handleProjectChange = (e) => {
        setSelectedProject(e.target.value);

        const formData = new FormData();
        formData.append('id', note.id);
        formData.append('project_id', e.target.value);
        formData.append('content', note.content);

        axios.post('/api/notes/update.php', formData)
            .then(response => {
                if (response.data.success) {
                    console.log('Note project updated successfully');
                } else {
                    console.error('Error updating note project:', response.data.message || 'Unknown error');
                }
            })
            .catch(error => {
                console.error('Error updating note project:', error.message || 'Unknown error');
            });
    };

    const projectColor = selectedProject
        ? projects.find(p => p.id === parseInt(selectedProject))?.color
        : '#000'; // черный цвет по умолчанию, если проект не выбран

    return (
        <li className={`list-group-item hover ${isActive ? 'active' : ''}`} onClick={onClick}>
            <div className="view" id={`note-${note.id}`}>
                <button className="destroy close hover-action">×</button>
                <div className="note-name">
                    <span
                        className="project-color-circle"
                        style={{
                            display: 'inline-block',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: projectColor,
                            marginRight: '10px'
                        }}
                    ></span>
                    <strong>{note.title}</strong>
                </div>
                <div className="note-desc">{note.description}</div>
                <span className="text-xs text-muted">{note.created_at}</span>
                <div>
                    <select value={selectedProject} onChange={handleProjectChange} className="form-control">
                        <option value="">Без проекта</option>
                        {projects.map(project => (
                            <option key={project.id} value={project.id}>
                                {project.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </li>
    );
}

export default NoteItem;
