import React, { useState, useEffect } from 'react';
import axios from 'axios';

function TaskItem({ task, activeTaskId, editTaskId, editTitle, handleTaskClick, handleDoubleClick, handleTitleChange, handleKeyDown, finishEditing, toggleDropdown, toggleProjectDropdown, closeTask, toggleDone, projects, assignTaskToProject, togglePin, dropdownOpenIndex, projectDropdownOpenIndex }) {
    const [domains, setDomains] = useState([]);

    useEffect(() => {
        // Получаем список доменов с их псевдонимами при загрузке компонента
        axios.get('/api/domains/read.php')
            .then(response => {
                setDomains(response.data);
            })
            .catch(error => {
                console.error('Ошибка при получении доменов:', error);
            });
    }, []);

    // Функция для получения псевдонима домена
    const getDomainPseudonym = (domain) => {
        const matchedDomain = domains.find(d => d.domain === domain);
        return matchedDomain ? matchedDomain.pseudonym : domain;  // Возвращаем псевдоним или сам домен
    };

    if (!task) {
        return null;
    }

    const isDropdownOpen = dropdownOpenIndex === task.id;
    const isProjectDropdownOpen = projectDropdownOpenIndex === task.id;
    const isEditing = editTaskId === task.id;

    return (
        <li
            className={`list-group-item ${activeTaskId === task.id ? "active" : ""} ${task.done ? "done" : ""} ${!task.done && task.passed ? "passed" : ""}`} //list-group-item hover
            style={{ minHeight: task.projectName ? '60px' : '40px' }}
            onClick={(e) => handleTaskClick(e, task.id)}  // Убедитесь, что task.id передается правильно
            onDoubleClick={() => handleDoubleClick(task.id)}
        >
            <div className="view" id={`task-${task.id}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ flex: 1 }}>
                    {!isEditing ? (
                        <span className="task-name">{task.title}</span>
                    ) : (
                        <input
                            className="edit form-control"
                            type="text"
                            value={editTitle}
                            onChange={(e) => handleTitleChange(e)}
                            onKeyDown={(e) => handleKeyDown(e, task.id)}
                            onBlur={() => finishEditing(task.id)}
                            autoFocus
                            style={{ width: '100%', color: 'black' }}  // Добавляем стиль для черного текста
                        />
                    )}
                    {task.projectName && (
                        <div style={{ fontSize: '0.9em', color: task.projectColor, marginTop: '5px' }}>
                            {task.projectName}
                        </div>
                    )}
                    {task.source_domain && (
                        <div>
                            <span className="label bg-primary">
                                Поставлено от: {getDomainPseudonym(task.source_domain)} ({task.source_domain})
                            </span>
                        </div>
                    )}
                </div>
                <div className="task-actions" style={{ display: 'flex', gap: '5px' }}>
                    <div className={`btn-group pull-right ${isDropdownOpen ? "open" : ""}`}>
                        <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" onClick={(e) => toggleDropdown(e, task.id)}>
                            X
                        </button>
                        <ul className="dropdown-menu" style={{ display: isDropdownOpen ? 'block' : 'none' }}>
                            <li><a href="#" onClick={(e) => { e.preventDefault(); closeTask(task.id); }}>Закрыть задачу</a></li>
                        </ul>
                    </div>
                    <div className="btn-group pull-right">
                        <button type="button" className="btn btn-default" onClick={(e) => toggleDone(e, task.id)}>
                            <i className="fa fa-check"></i>
                        </button>
                    </div>
                    <div className={`btn-group pull-right ${isProjectDropdownOpen ? "open" : ""}`}>
                        <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" onClick={(e) => toggleProjectDropdown(e, task.id)}>
                            <i className="fa fa-folder"></i>
                        </button>
                        <ul className="dropdown-menu" style={{ display: isProjectDropdownOpen ? 'block' : 'none' }}>
                            <li><a href="#" onClick={(e) => { e.preventDefault(); assignTaskToProject(task.id, null); }}>Без проекта</a></li>
                            {projects.map(project => (
                                <li key={project.id}><a href="#" onClick={(e) => { e.preventDefault(); assignTaskToProject(task.id, project.id); }}>{project.name}</a></li>
                            ))}
                        </ul>
                    </div>
                    <div className="btn-group pull-right">
                        <button type="button" className={`btn btn-default ${task.pinned ? "active" : ""}`} onClick={(e) => togglePin(e, task.id)}>
                            <i className={`fa ${task.pinned ? "fa-thumb-tack" : "fa-thumb-tack-o"}`}></i>
                        </button>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default TaskItem;
