import React from 'react';

function TimelineItem({ date, completedTasks, createdTasks }) {
    return (
        <li>
            <div className="content">
                <h4>{date}</h4>
                {createdTasks.length > 0 && (
                    <p><strong>Поставлены задачи:</strong> {createdTasks.join(', ')}</p>
                )}
                {completedTasks.length > 0 && (
                    <p><strong style={{ color: 'green' }}>Выполнено:</strong> {completedTasks.join(', ')}</p>
                )}
            </div>
        </li>
    );
}

export default TimelineItem;
