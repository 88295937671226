import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function TaskDetail({ selectedTask, updateTaskDescription, selectedProject }) {
    const [description, setDescription] = useState('');
    const [dueDate, setDueDate] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    useEffect(() => {
        if (selectedTask) {
            setDescription(selectedTask.description);
            setDueDate(selectedTask.due_date ? new Date(selectedTask.due_date) : null);
            fetchComments(selectedTask.id);
        }
    }, [selectedTask]);

    const fetchComments = (taskId) => {
        axios.get(`/api/comments/read_comments.php?task_id=${taskId}`)
            .then(response => {
                setComments(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the comments!', error);
            });
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleBlur = () => {
        if (selectedTask && selectedTask.description !== description) {
            updateTaskDescription(selectedTask.id, description, selectedProject); // Передаем selectedProject
        }
    };

    const handleNewCommentChange = (e) => {
        setNewComment(e.target.value);
    };

    const handleAddComment = () => {
        if (newComment.trim() !== '') {
            axios.post('/api/comments/create_comment.php', { task_id: selectedTask.id, comment_text: newComment })
                .then(response => {
                    setComments([...comments, { id: response.data.id, comment_text: newComment, created_at: new Date().toISOString() }]);
                    setNewComment('');
                })
                .catch(error => {
                    console.error('There was an error adding the comment!', error);
                });
        }
    };

    const handleDueDateChange = (date) => {
        setDueDate(date);
    };

    const handleSaveDueDate = () => {
        axios.post('/api/tasks/update.php', { id: selectedTask.id, due_date: dueDate ? dueDate.toISOString().split('T')[0] : null })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error('There was an error updating the due date!', error);
            });
    };

    return (
        <aside className="col-lg-4 bg-white">
            <section className="vertical_container flex with-border-left" id="task-detail">
                <header className="header bg-light lt with-border-bottom">
                    <p className="m-b">
                        <span className="text-muted">Created:</span> {selectedTask ? new Date(selectedTask.created_at).toLocaleString() : ''}
                    </p>
                    <p className="m-b">
                        <span className="text-muted">Due Date:</span>
                        <div className="input-group">
                            <DatePicker
                                selected={dueDate}
                                onChange={handleDueDateChange}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select due date"
                                className="form-control"
                            />
                            <span className="input-group-btn">
                                <button className="btn btn-success" type="button" onClick={handleSaveDueDate}>
                                    <i className="fa fa-check"></i>
                                </button>
                            </span>
                        </div>
                        </p>
                    <div className="lter pull-in b-t m-t-xxs">
                        <textarea
                            type="text"
                            className="form-control form-control-trans scrollable"
                            placeholder="Task description"
                            value={description}
                            onChange={handleDescriptionChange}
                            onBlur={handleBlur}
                        ></textarea>
                    </div>
                </header>
                <section className="w-f">
                    <section>
                        <section>
                            <ul id="task-comment" className="list-group no-radius no-border m-t-n-xxs">
                                {comments.map(comment => (
                                    <li key={comment.id} className="list-group-item hover">
                                        <div className="view">
                                            <div>
                                                <span>{comment.comment_text}</span>
                                                <small className="text-muted block text-xs">
                                                    <i className="fa fa-clock-o"></i> {new Date(comment.created_at).toLocaleString()}
                                                </small>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </section>
                    </section>
                </section>
                <footer className="footer bg-light lighter clearfix b-t">
                    <div className="input-group m-t-sm">
                        <input
                            type="text"
                            className="form-control input-sm"
                            id="task-c-input"
                            placeholder="Type a comment"
                            value={newComment}
                            onChange={handleNewCommentChange}
                        />
                        <span className="input-group-btn">
                            <button className="btn btn-success btn-sm" type="button" id="task-c-btn" onClick={handleAddComment}>
                                <i className="fa fa-pencil"></i>
                            </button>
                        </span>
                    </div>
                </footer>
            </section>
        </aside>
    );
}

export default TaskDetail;
