import React, { useState, useEffect } from 'react';

function Header({ addNewTask, addNewProject, onSearch, showProjects, toggleDateSeparators, showDateSeparators, projects, filterTasksByProject }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProject, setSelectedProject] = useState('');

    useEffect(() => {
        const savedProject = localStorage.getItem('selectedProject');
        if (savedProject) {
            setSelectedProject(savedProject);
            filterTasksByProject(savedProject);
        }
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        onSearch(searchTerm);
    };

    const handleProjectChange = (e) => {
        const projectId = e.target.value;
        setSelectedProject(projectId);
        filterTasksByProject(projectId);
        localStorage.setItem('selectedProject', projectId);
    };

    return (
        <header className="header bg-white with-border-bottom">
            <p className="h4 pull-left">{showProjects ? "Проекты" : "Задачи"}</p>
            {showProjects ? (
                <button className="btn btn-success btn-sm pull-right btn-icon" id="new-project" onClick={addNewProject}>
                    <i className="fa fa-plus"></i> {/* Замена задачи на проект */}
                </button>
            ) : (
                <button className="btn btn-success btn-sm pull-right btn-icon" id="new-task" onClick={addNewTask}>
                    <i className="fa fa-plus"></i>
                </button>
            )}
            {!showProjects && (
                <>
                    <button
                        className="btn btn-sm btn-default pull-right m-r-sm"
                        onClick={toggleDateSeparators}
                    >
                        {showDateSeparators ? "Отключить даты" : "Включить даты"}
                    </button>
                    <select
                        className="btn btn-sm btn-default pull-right m-r-sm"
                        value={selectedProject}
                        onChange={handleProjectChange}
                    >
                        <option value="">Все проекты</option>
                        {projects.map(project => (
                            <option key={project.id} value={project.id}>{project.name}</option>
                        ))}
                    </select>
                    <form role="search" className="navbar-form navbar-left m-t-sm pull-right" onSubmit={handleSearchSubmit}>
                        <div className="form-group">
                            <div className="input-group input-s">
                                <input
                                    type="text"
                                    placeholder="Поиск"
                                    className="form-control input-sm"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                                <span className="input-group-btn">
                                    <button type="submit" className="btn btn-sm btn-success btn-icon">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </header>
    );
}

export default Header;
