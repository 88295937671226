const MESSAGES = {
    NAVIGATION: {
        TASKS: "Задачи",
        PROJECTS: "Проекты",
        NOTES: "Заметки",
        REMINDERS: "Напоминания",
        TIMELINE: "Таймлайн",
        SETTINGS: "Настройки",
        EXTERNAL: "Внешние данные"
    },
    TIMELINE: {
        TIMELINE_TITLE: "Таймлайн1",
        TIMELINE_PICK_PROJECT: "Выберите проект1",
        TIMELINE_CLEAR_DATES: "Очистить даты1",
        TIMELINE_DOWNLOAD_PDF: "Скачать в PDF1",
    }
};

export default MESSAGES;
