import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MESSAGES from './lang';
import NavigationBar from './templates/tasks/navigationBar';
import ContentArea from './templates/tasks/contentArea';
import Notes from './templates/tasks/Notes/Notes';
import Reminders from './templates/tasks/Reminders/Reminders';
import Timeline from './templates/tasks/Timeline/Timeline';
import Settings from './templates/tasks/Settings/Settings';
import External from './templates/tasks/External/External'; // Импортируем новый компонент External

function App() {
    const [view, setView] = useState('tasks'); // 'tasks', 'projects', 'notes', 'reminders', 'timeline', 'settings', 'external'

    const handleShowProjects = () => {
        setView('projects');
    };

    const handleShowTasks = () => {
        setView('tasks');
    };

    const handleShowNotes = () => {
        setView('notes');
    };

    const handleShowReminders = () => {
        setView('reminders');
    };

    const handleShowTimeline = () => {
        setView('timeline');
    };

    const handleShowSettings = () => {
        setView('settings');
    };

    const handleShowExternal = () => {
        setView('external'); // Устанавливаем состояние для раздела External
    };

    return (
        <>
            <NavigationBar 
                onShowTasks={handleShowTasks} 
                onShowProjects={handleShowProjects} 
                onShowNotes={handleShowNotes} 
                onShowReminders={handleShowReminders} 
                onShowTimeline={handleShowTimeline} 
                onShowSettings={handleShowSettings}
                onShowExternal={handleShowExternal}
                messages={MESSAGES.NAVIGATION}
            />
            <section id="content">
                {view === 'tasks' && <ContentArea showProjects={false} />}
                {view === 'projects' && <ContentArea showProjects={true} />}
                {view === 'notes' && <Notes />}
                {view === 'reminders' && <Reminders />}
                {view === 'timeline' && <Timeline messages={MESSAGES.TIMELINE} />}
                {view === 'settings' && <Settings />}
                {view === 'external' && <External />} {/* Отображаем новый компонент External */}
            </section>
        </>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
