import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TimelineItem from './TimelineItem';

function TimelineContent({ projectId, startDate, endDate, period }) {
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        if (projectId && startDate && endDate) {
            axios.get(`/api/timeline/read.php`, {
                params: {
                    projectId: projectId,
                    startDate: startDate,
                    endDate: endDate,
                    period: period
                }
            })
            .then(response => {
                setTasks(response.data);
            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
            });
        } else {
            setTasks([]);
        }
    }, [projectId, startDate, endDate, period]);

    const monthNames = [
        'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня',
        'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'
    ];

    const groupedTasks = tasks.reduce((acc, task) => {
        const creationDate = new Date(task.created_at);
        const completionDate = new Date(task.updated_at);
    
        // Проверка, была ли задача завершена в выбранный период
        const isCompletedInPeriod = task.status === 1 && completionDate >= new Date(startDate) && completionDate <= new Date(endDate);
    
        // Проверка, была ли задача создана в выбранный период
        const isCreatedInPeriod = creationDate >= new Date(startDate) && creationDate <= new Date(endDate);
    
        // Если задача завершена в период и создана вне периода, показываем только завершение
        if (isCompletedInPeriod && !isCreatedInPeriod) {
            const completionKey = completionDate.toISOString().split('T')[0];
            const completionDateFormatted = `${completionDate.getDate()} ${monthNames[completionDate.getMonth()]} ${completionDate.getFullYear()}`;
            
            if (!acc[completionKey]) acc[completionKey] = { date: completionDateFormatted, created: [], completed: [] };
            acc[completionKey].completed.push(task.title);
        }
    
        // Если задача создана в период, показываем её как обычно
        if (isCreatedInPeriod) {
            const creationKey = creationDate.toISOString().split('T')[0];
            const creationDateFormatted = `${creationDate.getDate()} ${monthNames[creationDate.getMonth()]} ${creationDate.getFullYear()}`;
    
            if (!acc[creationKey]) acc[creationKey] = { date: creationDateFormatted, created: [], completed: [] };
            acc[creationKey].created.push(task.title);
    
            // Если задача также завершена в период, показываем и её завершение
            if (isCompletedInPeriod) {
                const completionKey = completionDate.toISOString().split('T')[0];
                const completionDateFormatted = `${completionDate.getDate()} ${monthNames[completionDate.getMonth()]} ${completionDate.getFullYear()}`;
    
                if (!acc[completionKey]) acc[completionKey] = { date: completionDateFormatted, created: [], completed: [] };
                acc[completionKey].completed.push(task.title);
            }
        }
    
        return acc;
    }, {});
    
    const sortedDates = Object.keys(groupedTasks).sort((a, b) => new Date(a) - new Date(b));

    return (
        <div className="timeline-container">
            <ul className="timeline">
                {sortedDates.map(dateKey => (
                    <TimelineItem
                        key={dateKey}
                        date={groupedTasks[dateKey].date}
                        createdTasks={groupedTasks[dateKey].created}
                        completedTasks={groupedTasks[dateKey].completed}
                    />
                ))}
            </ul>
        </div>
    );
}

export default TimelineContent;
