import React, { useState } from 'react';
import axios from 'axios';
import { HexColorPicker } from 'react-colorful';
import ProjectDetail from './ProjectDetail';

function Projects({ projects, setProjects }) {
    const [editIndex, setEditIndex] = useState(null);
    const [editName, setEditName] = useState('');
    const [editWebsite, setEditWebsite] = useState('');
    const [editColor, setEditColor] = useState('#000000');
    const [textColor, setTextColor] = useState('#000000');
    const [selectedProject, setSelectedProject] = useState(null); // Новый стейт для выбора проекта

    const handleEditClick = (index, project) => {
        setEditIndex(index);
        setEditName(project.name);
        setEditWebsite(project.website);
        setEditColor(project.color || '#000000');
        setTextColor(project.textColor || '#000000');
    };

    const handleSaveClick = (projectId, index) => {
        const updatedProject = {
            id: projectId,
            name: editName,
            website: editWebsite,
            color: editColor,
            textColor: textColor
        };

        axios.post('/api/projects/update.php', updatedProject)
            .then(response => {
                console.log(response.data);
                setProjects(prevProjects => {
                    const updatedProjects = [...prevProjects];
                    updatedProjects[index] = {
                        ...updatedProject,
                        tasks_completed: projects[index].tasks_completed,
                        tasks_overdue: projects[index].tasks_overdue,
                        tasks_in_progress: projects[index].tasks_in_progress,
                        notes: projects[index].notes
                    };
                    return updatedProjects;
                });
                setEditIndex(null);
            })
            .catch(error => {
                console.error('There was an error updating the project!', error);
            });
    };

    const handleArrowClick = (project) => {
        setSelectedProject(project); // Устанавливаем выбранный проект
    };

    return (
        <div className="row">
            {selectedProject ? (
                <ProjectDetail project={selectedProject} setSelectedProject={setSelectedProject} setProjects={setProjects} />

            ) : (
                projects.map((project, index) => (
                    <div className="col-lg-3" key={index}>
                        <section className="panel">
                            <div className="panel-heading no-borders" style={{ backgroundColor: editIndex === index ? editColor : (project.color || '#000000'), color: editIndex === index ? textColor : (project.textColor || '#000000') }}>
                                <div className="clearfix">
                                    <div className="clear">
                                        {editIndex === index ? (
                                            <>
                                                <div className="h3 m-t-xs m-b-xs">
                                                    <input
                                                        type="text"
                                                        value={editName}
                                                        onChange={(e) => setEditName(e.target.value)}
                                                        style={{ color: '#000000' }}
                                                    />
                                                    <a href="#" className="pull-right" onClick={() => handleSaveClick(project.id, index)}>
                                                        <i className="fa fa-check" style={{ color: textColor }}></i>
                                                    </a>
                                                </div>
                                                <small className="text-muted">
                                                    <input
                                                        type="text"
                                                        value={editWebsite}
                                                        onChange={(e) => setEditWebsite(e.target.value)}
                                                        style={{ color: '#000000' }}
                                                    />
                                                </small>
                                                <div className="color-picker-container">
                                                    <p>Цвет проекта</p>
                                                    <HexColorPicker color={editColor} onChange={setEditColor} />
                                                </div>
                                                <div className="text-color-picker-container">
                                                    <p>Цвет названия проекта</p>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value="#000000"
                                                            checked={textColor === '#000000'}
                                                            onChange={() => setTextColor('#000000')}
                                                        />
                                                        Черный
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value="#FFFFFF"
                                                            checked={textColor === '#FFFFFF'}
                                                            onChange={() => setTextColor('#FFFFFF')}
                                                        />
                                                        Белый
                                                    </label>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="h3 m-t-xs m-b-xs">
                                                    {project.name}
                                                    <div className="pull-right">
                                                        <a href="#" onClick={() => handleEditClick(index, project)}>
                                                            <i className="fa fa-cogs" style={{ color: project.textColor || '#000000' }}></i>
                                                        </a>
                                                        <a href="#" onClick={() => handleArrowClick(project)}>
                                                            <i className="fa fa-arrow-right" style={{ color: project.textColor || '#000000', marginLeft: '10px' }}></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <small className="text-muted">
                                                    <a href={project.website} target="_blank" rel="noopener noreferrer" style={{ color: project.textColor || '#000000' }}>
                                                        {project.website}
                                                    </a>
                                                </small>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="list-group no-radius alt">
                                <a className="list-group-item" href="#">
                                    <span className="badge bg-success">{project.tasks_completed}</span>
                                    Задач выполнено
                                </a>
                                <a className="list-group-item" href="#">
                                    <span className="badge bg-danger">{project.tasks_overdue}</span>
                                    Задач просрочено
                                </a>
                                <a className="list-group-item" href="#">
                                    <span className="badge bg-light">{project.tasks_in_progress}</span>
                                    Задач в работе
                                </a>
                                <a className="list-group-item" href="#">
                                    <span className="badge bg-light">{project.notes}</span>
                                    Заметок
                                </a>
                            </div>
                        </section>
                    </div>
                ))
            )}
        </div>
    );
}

export default Projects;