import React from 'react';

function NavigationBar({ onShowTasks, onShowProjects, onShowNotes, onShowReminders, onShowTimeline, onShowSettings, onShowExternal, messages }) {
    return (
        <aside className="bg-dark nav-vertical" id="nav">{/*bg-dark aside-md nav-vertical*/}
            <section className="vertical_container">
                <header className="nav-bar dker">
                    <a className="btn btn-link visible-xs" data-toggle="class:nav-off-screen" data-target="#nav">
                        <i className="fa fa-bars"></i>
                    </a>
                    <a href="#" className="nav-brand" data-toggle="fullscreen">8</a>
                    <a className="btn btn-link visible-xs" data-toggle="class:show" data-target=".nav-user">
                        <i className="fa fa-comment-o"></i>
                    </a>
                </header>
                <section>
                    <nav className="nav-primary hidden-xs">
                        <ul className="nav">
                            <li>
                                <a href="#" onClick={(e) => { e.preventDefault(); onShowTasks(); }}>
                                    <i className="fa fa-tasks"></i> <span>{messages.TASKS}</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={(e) => { e.preventDefault(); onShowProjects(); }}>
                                    <i className="fa fa-folder"></i> <span>{messages.PROJECTS}</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={(e) => { e.preventDefault(); onShowNotes(); }}>
                                    <i className="fa fa-pencil"></i> <span>{messages.NOTES}</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={(e) => { e.preventDefault(); onShowReminders(); }}>
                                    <i className="fa fa-bell"></i> <span>{messages.REMINDERS}</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={(e) => { e.preventDefault(); onShowTimeline(); }}>
                                    <i className="fa fa-clock-o"></i> <span>{messages.TIMELINE}</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={(e) => { e.preventDefault(); onShowSettings(); }}>
                                    <i className="fa fa-cog"></i> <span>{messages.SETTINGS}</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={(e) => { e.preventDefault(); onShowExternal(); }}>
                                    <i className="fa fa-external-link"></i> <span>{messages.EXTERNAL}</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </section>
                <footer className="footer bg-gradient hidden-xs">
                    <a href="" data-toggle="ajaxModal" className="btn btn-sm btn-link m-r-n-xs pull-right">
                        <i className="fa fa-power-off"></i>
                    </a>
                </footer>
            </section>
        </aside>
    );
}

export default NavigationBar;
