import React from 'react';

function UpcomingReminders({ reminders, showUpcomingReminders, toggleUpcomingReminders }) {
    return (
        <>
            {reminders.length > 0 && (
                <div className="alert alert-warning alert-block">
                    {showUpcomingReminders && (
                        <>
                            <p>Предстоящие напоминания:</p>
                            <div>
                                {reminders.map(reminder => {
                                    const isOverdue = new Date(reminder.reminder_date) < new Date();
                                    return (
                                        <span 
                                            key={reminder.id} 
                                            className={`label ${isOverdue ? 'bg-danger' : 'bg-warning'}`} 
                                            style={{ display: 'inline-block', marginRight: '10px' }}
                                        >
                                            {reminder.text} - <i className="fa fa-clock-o"></i> {new Date(reminder.reminder_date).toLocaleDateString()}
                                        </span>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    <a href="#" onClick={toggleUpcomingReminders} className="pull-right" style={{ marginLeft: 'auto' }}>
                        <i className={`fa fa-chevron-${showUpcomingReminders ? 'up' : 'down'}`} style={{ verticalAlign: 'middle', zIndex: '999' }}></i>
                    </a>
                </div>
            )}
        </>
    );
}

export default UpcomingReminders;
