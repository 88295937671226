import axios from 'axios';

export const fetchTasks = async (setTasks, setFilteredTasks, projects) => {
    try {
        const response = await axios.get('/api/tasks/read.php');
        const currentDate = new Date();
        const tasksWithStatus = response.data.map(task => ({
            ...task,
            projectName: task.project_name,
            projectTextColor: task.project_text_color,
            projectColor: task.project_color,
            projectId: task.project_id,
            isEditing: false,
            done: task.status === 1,
            passed: task.status !== 1 && task.due_date && new Date(task.due_date) < currentDate,
            pinned: task.pinned
        }));
        tasksWithStatus.sort((a, b) => {
            if (a.passed && !b.passed) return -1;
            if (b.passed && !a.passed) return 1;
            if (a.pinned && !b.pinned) return -1;
            if (b.pinned && !a.pinned) return 1;
            return new Date(b.created_at) - new Date(a.created_at);
        });
        setTasks(tasksWithStatus);
        setFilteredTasks(tasksWithStatus);
        return tasksWithStatus; // Возвращаем задачи для последующей фильтрации
    } catch (error) {
        console.error('There was an error fetching the tasks!', error);
    }
};






export const fetchProjects = (setProjects) => {
    return axios.get('/api/projects/read.php')
        .then(response => {
            setProjects(response.data);
            return response.data;
        })
        .catch(error => {
            console.error('There was an error fetching the projects!', error);
        });
};


export const addNewTask = (setTasks, setFilteredTasks, setSelectedTask, selectedProject, projects) => {
    const newTask = {
        title: "Новая задача",
        description: "Описание задачи",
        status: false,
        isEditing: false,
        done: false,
        passed: false,
        created_at: new Date().toISOString(),
        project_id: selectedProject ? parseInt(selectedProject, 10) : null
    };

    axios.post('/api/tasks/create.php', newTask)
        .then(response => {
            const createdTask = { 
                ...newTask, 
                id: response.data.id,
                projectName: null,
                projectColor: null,
                projectTextColor: null
            };
            
            // Если задача привязана к проекту, добавляем информацию о проекте
            if (selectedProject) {
                const project = projects.find(p => p.id === parseInt(selectedProject, 10));
                if (project) {
                    createdTask.projectName = project.name;
                    createdTask.projectColor = project.color;
                    createdTask.projectTextColor = project.textColor;
                }
            }

            setTasks(prevTasks => {
                const updatedTasks = [createdTask, ...prevTasks];
                updatedTasks.sort((a, b) => {
                    if (a.passed && !b.passed) return -1;
                    if (!a.passed && b.passed) return 1;
                    return new Date(b.created_at) - new Date(a.created_at);
                });

                // Применяем фильтрацию по проекту, если выбран проект
                if (selectedProject) {
                    setFilteredTasks(updatedTasks.filter(task => task.project_id === parseInt(selectedProject, 10)));
                } else {
                    setFilteredTasks(updatedTasks);
                }

                return updatedTasks;
            });
            setSelectedTask(createdTask);
        })
        .catch(error => {
            console.error('There was an error creating the task!', error);
        });
};


export const handleTaskClick = (e, taskId, filteredTasks, setSelectedTask, setActiveTaskIndex) => {
    e.stopPropagation();
    const selectedTask = filteredTasks.find(task => task.id === taskId);
    setSelectedTask(selectedTask);
    setActiveTaskIndex(taskId); // Устанавливаем activeTaskId
};

export const handleDoubleClick = (taskId, filteredTasks, setEditTaskId, setEditTitle) => {
    const task = filteredTasks.find(task => task.id === taskId);
    if (task) {
        setEditTaskId(task.id);
        setEditTitle(task.title);
    }
};

export const handleTitleChange = (e, setEditTitle) => {
    setEditTitle(e.target.value);
};

export const handleKeyDown = (e, taskId, finishEditing) => {
    if (e.key === 'Enter') {
        finishEditing(taskId);
    }
};

export const finishEditing = (taskId, tasks, editTitle, setTasks, setFilteredTasks, setEditTaskId, setEditTitle, selectedProject) => {
    const updatedTasks = tasks.map(task => {
        if (task.id === taskId) {
            return { ...task, title: editTitle, isEditing: false };
        }
        return task;
    });

    // Обновляем общий список задач
    setTasks(updatedTasks);

    // Применяем фильтрацию, если выбран проект
    if (selectedProject) {
        const filteredTasks = updatedTasks.filter(task => task.project_id === parseInt(selectedProject, 10));
        setFilteredTasks(filteredTasks);
    } else {
        setFilteredTasks(updatedTasks);
    }

    // Сбрасываем состояние редактирования
    setEditTaskId(null);
    setEditTitle('');

    // Отправляем запрос на сервер для обновления задачи
    axios.post('/api/tasks/update.php', { id: taskId, title: editTitle })
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.error('There was an error updating the task title!', error);
        });
};


export const toggleDropdown = (e, taskId, dropdownOpenIndex, setDropdownOpenIndex) => {
    e.stopPropagation();
    setDropdownOpenIndex(dropdownOpenIndex === taskId ? null : taskId);
};

export const toggleProjectDropdown = (e, taskId, projectDropdownOpenIndex, setProjectDropdownOpenIndex) => {
    e.stopPropagation();
    setProjectDropdownOpenIndex(projectDropdownOpenIndex === taskId ? null : taskId);
};

export const closeTask = (taskId, tasks, setTasks, setFilteredTasks, setDropdownOpenIndex, selectedProject) => {
    const updatedTasks = tasks.filter(task => task.id !== taskId);

    setTasks(updatedTasks);

    // Применяем фильтрацию, если выбран проект
    if (selectedProject) {
        const filteredTasks = updatedTasks.filter(task => task.project_id === parseInt(selectedProject, 10));
        setFilteredTasks(filteredTasks);
    } else {
        setFilteredTasks(updatedTasks);
    }

    setDropdownOpenIndex(null); // Сбрасываем индекс открытого дропдауна

    axios.post('/api/tasks/delete.php', { id: taskId })
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.error('There was an error deleting the task!', error);
        });
};

export const toggleDone = (e, taskId, tasks, filteredTasks, setTasks, setFilteredTasks, selectedProject) => {
    e.stopPropagation();

    const updatedTasks = tasks.map(task => {
        if (task.id === taskId) {
            const isTaskDone = !task.done;
            const isTaskPassed = !isTaskDone && task.due_date && new Date(task.due_date) < new Date();

            return {
                ...task,
                done: isTaskDone,
                status: isTaskDone ? 1 : 0,
                passed: isTaskPassed,
            };
        }
        return task;
    });

    // Сортировка задач
    updatedTasks.sort((a, b) => {
        if (a.passed && !a.done && (!b.passed || b.done)) return -1;
        if ((!a.passed || a.done) && b.passed && !b.done) return 1;
        return new Date(b.created_at) - new Date(a.created_at);
    });

    setTasks(updatedTasks);

    // Применяем фильтрацию по проекту, если выбран проект
    if (selectedProject) {
        const filteredTasks = updatedTasks.filter(task => task.project_id === parseInt(selectedProject, 10));
        setFilteredTasks(filteredTasks);
    } else {
        setFilteredTasks(updatedTasks);
    }

    // Отправляем правильный статус задачи на сервер
    axios.post('/api/tasks/update.php', { id: taskId, status: updatedTasks.find(task => task.id === taskId).status })
        .then(response => {
            console.log('Task status updated:', response.data);
        })
        .catch(error => {
            console.error('There was an error updating the task status!', error);
        });
};


export const handleSearch = (searchTerm, tasks, setFilteredTasks) => {
    if (searchTerm) {
        axios.get(`/api/tasks/search.php?q=${searchTerm}`)
            .then(response => {
                const currentDate = new Date();
                const tasksWithStatus = response.data.map(task => ({
                    ...task,
                    isEditing: false,
                    done: task.status === 1,
                    passed: task.due_date && new Date(task.due_date) < currentDate
                }));
                tasksWithStatus.sort((a, b) => {
                    if (a.passed && !b.passed) return -1;
                    if (!a.passed && b.passed) return 1;
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                setFilteredTasks(tasksWithStatus);
            })
            .catch(error => {
                console.error('There was an error searching the tasks!', error);
            });
    } else {
        setFilteredTasks(tasks);
    }
};

export const updateTaskDescription = (taskId, description, tasks, setTasks, setFilteredTasks, selectedProject) => {
    const updatedTasks = tasks.map(task =>
        task.id === taskId ? { ...task, description } : task
    );
    setTasks(updatedTasks);

    // Применяем фильтрацию по проекту, если выбран проект
    if (selectedProject) {
        const filteredTasks = updatedTasks.filter(task => task.project_id === parseInt(selectedProject, 10));
        setFilteredTasks(filteredTasks);
    } else {
        setFilteredTasks(updatedTasks);
    }

    axios.post('/api/tasks/update.php', { id: taskId, description })
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.error('There was an error updating the task description!', error);
        });
};


export const assignTaskToProject = (taskId, projectId, tasks, setTasks, setFilteredTasks, projects, selectedProject) => {
    const updatedTasks = tasks.map(task => {
        if (task.id === taskId) {
            const project = projects.find(p => p.id === projectId);
            return {
                ...task,
                project_id: projectId,
                projectName: project ? project.name : null,
                projectTextColor: project ? project.textColor : null,
                projectColor: project ? project.color : null,
            };
        }
        return task;
    });

    // Сортировка задач
    updatedTasks.sort((a, b) => {
        if (a.pinned && !b.pinned) return -1;
        if (!a.pinned && b.pinned) return 1;
        if (a.passed && !b.passed) return -1;
        if (!a.passed && b.passed) return 1;
        return new Date(b.created_at) - new Date(a.created_at);
    });

    setTasks(updatedTasks);

    // Применяем фильтрацию по проекту, если выбран проект
    if (selectedProject) {
        const filteredTasks = updatedTasks.filter(task => task.project_id === parseInt(selectedProject, 10));
        setFilteredTasks(filteredTasks);
    } else {
        setFilteredTasks(updatedTasks);
    }

    axios.post('/api/tasks/assign.php', { id: taskId, project_id: projectId })
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.error('There was an error assigning the task to the project!', error);
        });
};

