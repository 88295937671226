import React from 'react';

function SettingsHeader() {
    return (
        <header className="header bg-white with-border-bottom b-light">
            <p className="h4 font-thin m-r-sm m-b-sm pull-left">Настройки</p>
        </header>
    );
}

export default SettingsHeader;
