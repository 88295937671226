import React, { useState } from 'react';
import axios from 'axios';
import { HexColorPicker } from 'react-colorful';

function ProjectDetail({ project, setSelectedProject, setProjects }) {
    const [editName, setEditName] = useState(project.name);
    const [editWebsite, setEditWebsite] = useState(project.website);
    const [editColor, setEditColor] = useState(project.color || '#000000');
    const [textColor, setTextColor] = useState(project.textColor || '#000000');

    const [reportStartDate, setReportStartDate] = useState('');
    const [reportEndDate, setReportEndDate] = useState('');
    const [reportOutput, setReportOutput] = useState(''); // Для отображения отчета
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSaveClick = () => {
        const updatedProject = {
            id: project.id,
            name: editName,
            website: editWebsite,
            color: editColor,
            textColor: textColor
        };

        axios.post('/api/projects/update.php', updatedProject)
            .then(response => {
                setProjects(prevProjects => {
                    const updated = prevProjects.map(p =>
                        p.id === project.id ? { ...p, ...updatedProject } : p
                    );
                    return updated;
                });
            })
            .catch(error => {
                console.error('There was an error updating the project!', error);
            });
    };

    const handleGenerateReport = async () => {
        setLoading(true);
        setError('');
        setReportOutput('');
        
        try {
            // Получаем задачи проекта за указанный период
            const tasksResponse = await axios.get('/api/tasks/read_by_project.php', {
                params: {
                    project_id: project.id,
                    start_date: reportStartDate,
                    end_date: reportEndDate,
                },
            });

            const tasks = tasksResponse.data;

            // Отправляем данные на сервер для генерации отчета
            const reportResponse = await axios.post('/api/chatGPT/generate_report.php', {
                tasks,
                projectName: project.name,
                reportStartDate,
                reportEndDate,
            });

            if (reportResponse.data.success) {
                setReportOutput(reportResponse.data.report);
            } else {
                setError(reportResponse.data.error || 'Неизвестная ошибка при генерации отчета.');
            }

        } catch (err) {
            console.error('Ошибка при генерации отчета:', err);
            setError('Произошла ошибка при генерации отчета.');
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div className="project-detail row">
            {/* Левая колонка: Информация о проекте + Настройки */}
            <div className="col-md-4">
                <section className="panel">
                    <div className="panel-heading no-borders" style={{ backgroundColor: editColor, color: textColor }}>
                        <div className="clearfix">
                            <div className="clear">
                                <div className="h3 m-t-xs m-b-xs">
                                    <input
                                        type="text"
                                        value={editName}
                                        onChange={(e) => setEditName(e.target.value)}
                                        style={{ color: '#000000', width: '100%' }}
                                    />
                                </div>
                                <small className="text-muted">
                                    <input
                                        type="text"
                                        value={editWebsite}
                                        onChange={(e) => setEditWebsite(e.target.value)}
                                        style={{ color: '#000000', width: '100%' }}
                                    />
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="list-group no-radius alt">
                        <a className="list-group-item" href="#">
                            <span className="badge bg-success">{project.tasks_completed}</span>
                            Задач выполнено
                        </a>
                        <a className="list-group-item" href="#">
                            <span className="badge bg-danger">{project.tasks_overdue}</span>
                            Задач просрочено
                        </a>
                        <a className="list-group-item" href="#">
                            <span className="badge bg-light">{project.tasks_in_progress}</span>
                            Задач в работе
                        </a>
                        <a className="list-group-item" href="#">
                            <span className="badge bg-light">{project.notes}</span>
                            Заметок
                        </a>
                    </div>

                    <div className="panel-body">
                        <div className="color-picker-container">
                            <p>Цвет проекта</p>
                            <HexColorPicker color={editColor} onChange={setEditColor} />
                        </div>
                        <div className="text-color-picker-container">
                            <p>Цвет названия проекта</p>
                            <label>
                                <input
                                    type="radio"
                                    value="#000000"
                                    checked={textColor === '#000000'}
                                    onChange={() => setTextColor('#000000')}
                                />
                                Черный
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="#FFFFFF"
                                    checked={textColor === '#FFFFFF'}
                                    onChange={() => setTextColor('#FFFFFF')}
                                />
                                Белый
                            </label>
                        </div>
                        <button className="btn btn-primary m-t-sm" onClick={handleSaveClick}>
                            Сохранить изменения
                        </button>
                    </div>
                </section>
            </div>

            {/* Средняя колонка: Создание отчета */}
            <div className="col-md-4">
            <section className="panel">
                    <div className="panel-heading">
                        <h4>Создание отчета</h4>
                    </div>
                    <div className="panel-body">
                        <div className="form-group">
                            <label>Дата начала:</label>
                            <input
                                type="date"
                                className="form-control"
                                value={reportStartDate}
                                onChange={(e) => setReportStartDate(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Дата окончания:</label>
                            <input
                                type="date"
                                className="form-control"
                                value={reportEndDate}
                                onChange={(e) => setReportEndDate(e.target.value)}
                            />
                        </div>
                        <button className="btn btn-success" onClick={handleGenerateReport} disabled={loading}>
                            {loading ? 'Генерация...' : 'Сгенерировать отчет'}
                        </button>
                        {error && <div className="alert alert-danger m-t">{error}</div>}
                    </div>
                </section>
                {reportOutput && (
                    <section className="panel">
                        <div className="panel-heading">
                            <h4>Отчет</h4>
                        </div>
                        <div className="panel-body">
                            <pre style={{ whiteSpace: 'pre-wrap' }}>{reportOutput}</pre>
                        </div>
                    </section>
                )}
            </div>

            {/* Правая колонка: Чат */}
            <div className="col-md-4">
                <section className="panel">
                    <div className="panel-heading">
                        <h4>Чат</h4>
                    </div>
                    <div className="panel-body">
                        <p>Здесь будет чат проекта...</p>
                        {/* Заготовка для будущего функционала */}
                    </div>
                </section>
            </div>

            {/* Кнопка для возврата к списку проектов */}
            <div className="col-md-12">
                <button className="btn btn-default" onClick={() => setSelectedProject(null)}>
                    <i className="fa fa-arrow-left"></i> Назад к проектам
                </button>
            </div>
        </div>
    );
}

export default ProjectDetail;
