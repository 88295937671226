import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SettingsHeader from './SettingsHeader';

function Settings() {
    const [hideCompletedTasks, setHideCompletedTasks] = useState(false);
    const [domains, setDomains] = useState([]);
    const [newDomain, setNewDomain] = useState('');
    const [newPseudonym, setNewPseudonym] = useState(''); // Для ввода псевдонима

    useEffect(() => {
        // Получение текущих настроек из базы данных при загрузке компонента
        axios.get('/api/settings/read.php')
            .then(response => {
                const settings = response.data;
                const hideCompletedSetting = settings.find(s => s.setting_name === 'hide_completed_tasks');
                if (hideCompletedSetting) {
                    setHideCompletedTasks(hideCompletedSetting.setting_value === '1');
                }
            })
            .catch(error => {
                console.error('There was an error fetching the settings!', error);
            });

        // Получаем список доменов
        axios.get('/api/domains/read.php')
            .then(response => {
                setDomains(response.data);
            })
            .catch(error => {
                console.error('Error fetching domains:', error);
            });
    }, []);

    const handleSaveSettings = () => {
        // Сохраняем настройку "Скрывать выполненные задачи"
        axios.post('/api/settings/save.php', {
            setting_name: 'hide_completed_tasks',
            setting_value: hideCompletedTasks ? '1' : '0'
        })
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.error('There was an error saving the settings!', error);
        });

        // Сохраняем изменения для доменов
        domains.forEach((domain) => {
            axios.post('/api/domains/update.php', {
                id: domain.id,
                pseudonym: domain.pseudonym,
                can_set: domain.can_set,
                to_set: domain.to_set,
            })
            .then(response => {
                console.log('Domain updated:', response.data);
            })
            .catch(error => {
                console.error('Error updating domain:', error);
            });
        });
    };

    const handleAddDomain = () => {
        if (newDomain.trim() === '') return;

        axios.post('/api/domains/save.php', { domain: newDomain, pseudonym: newPseudonym, can_set: 1, to_set: 1 })
            .then(response => {
                setDomains(prev => [...prev, { id: response.data.id, domain: newDomain, pseudonym: newPseudonym, can_set: 1, to_set: 1 }]);
                setNewDomain('');
                setNewPseudonym(''); // Сброс значения после добавления
            })
            .catch(error => {
                console.error('Error adding domain:', error);
            });
    };

    const handleDeleteDomain = (id) => {
        axios.post('/api/domains/delete.php', { id })
            .then(response => {
                setDomains(prev => prev.filter(domain => domain.id !== id));
            })
            .catch(error => {
                console.error('Error deleting domain:', error);
            });
    };

    const handleDomainChange = (id, field, value) => {
        // Обновляем состояние локально, но не сохраняем в базу данных
        setDomains(domains.map(domain => {
            if (domain.id === id) {
                return { ...domain, [field]: value };
            }
            return domain;
        }));
    };

    return (
        <div>
            <SettingsHeader />
            <div className="settings-container">
                <div className="form-group">
                    <label>
                        <input
                            type="checkbox"
                            checked={hideCompletedTasks}
                            onChange={(e) => setHideCompletedTasks(e.target.checked)}
                        />
                        Скрывать выполненные задачи
                    </label>
                </div>

                <div className="domain-settings">
                    <h3>Домены, которые могут ставить задачи</h3>
                    <input
                        type="text"
                        value={newDomain}
                        onChange={(e) => setNewDomain(e.target.value)}
                        placeholder="Введите домен"
                    />
                    <input
                        type="text"
                        value={newPseudonym}
                        onChange={(e) => setNewPseudonym(e.target.value)}
                        placeholder="Введите название домена"
                    />
                    <button onClick={handleAddDomain} className="btn btn-primary">Добавить домен</button>
                    <ul>
                        {domains.map((domain) => (
                            <li key={domain.id}>
                                {domain.domain} - {domain.pseudonym || 'Без названия'}
                                <div>
                                    <input
                                        type="text"
                                        value={domain.pseudonym}
                                        onChange={(e) => handleDomainChange(domain.id, 'pseudonym', e.target.value)}
                                        placeholder="Введите название"
                                    />
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={domain.can_set}
                                            onChange={(e) => handleDomainChange(domain.id, 'can_set', e.target.checked ? 1 : 0)}
                                        />
                                        Может смотреть и ставить задачи
                                    </label>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={domain.to_set}
                                            onChange={(e) => handleDomainChange(domain.id, 'to_set', e.target.checked ? 1 : 0)}
                                        />
                                        Можно ставить задачи
                                    </label>
                                    <button onClick={() => handleDeleteDomain(domain.id)} className="btn btn-danger btn-sm">
                                        Удалить
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                <button className="btn btn-primary" onClick={handleSaveSettings}>Сохранить настройки</button>
            </div>
        </div>
    );
}

export default Settings;
