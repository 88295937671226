import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NotesList from './NotesList';

function Notes() {
    const [notes, setNotes] = useState([]);
    const [activeNoteId, setActiveNoteId] = useState(null);
    const [currentContent, setCurrentContent] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredNotes, setFilteredNotes] = useState([]);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');

    useEffect(() => {
        axios.get('/api/notes/read.php')
            .then(response => {
                const notesData = response.data;
                setNotes(notesData);
                setFilteredNotes(notesData); // Изначально показываем все заметки
                if (notesData.length > 0) {
                    setActiveNoteId(notesData[0].id);
                    setCurrentContent(notesData[0].content);
                }
            })
            .catch(error => {
                console.error('Error fetching notes:', error);
            });
    
        axios.get('/api/projects/read.php')
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
    }, []);
    
    useEffect(() => {
        applyProjectFilter(notes); // Применяем фильтр при изменении выбранного проекта или заметок
    }, [selectedProject, notes]);
    

    const handleNewNote = () => {
        const formData = new FormData();
        formData.append('title', 'New note');
        formData.append('description', 'empty note');
        formData.append('content', 'New note');
        formData.append('project_id', selectedProject || '');  // Если проект не выбран, передаем пустое значение
    
        axios.post('/api/notes/create.php', formData)
            .then(response => {
                if (response.data.success) {
                    const addedNote = {
                        id: response.data.id,
                        title: 'New note',
                        description: 'empty note',
                        content: 'New note',
                        project_id: selectedProject ? parseInt(selectedProject, 10) : null,
                        created_at: new Date().toLocaleString(),
                    };
                    
                    // Обновляем состояние notes, добавляя новую заметку
                    const updatedNotes = [addedNote, ...notes];
                    setNotes(updatedNotes);
                    
                    // Применяем фильтр по проекту
                    applyProjectFilter(updatedNotes);
                    
                    // Устанавливаем новую заметку активной
                    setActiveNoteId(addedNote.id);
                    setCurrentContent(addedNote.content);
                } else {
                    console.error('Note creation failed. Response:', response.data);
                }
            })
            .catch(error => {
                console.error('Error creating note:', error);
            });
    };
    

    const handleNoteClick = (id) => {
        const note = notes.find(note => note.id === id);
        setActiveNoteId(id);
        setCurrentContent(note.content);
    };

    const handleContentChange = (e) => {
        setCurrentContent(e.target.value);
    };

    const saveNoteChanges = () => {
        const formData = new FormData();
        formData.append('id', activeNoteId);
        formData.append('content', currentContent);

        const title = currentContent.split('\n')[0];
        const description = currentContent.split('\n').slice(1).join(' ');
        const trimmedDescription = description.length > 30 ? description.slice(0, 30) + '...' : description;

        axios.post('/api/notes/update.php', formData)
            .then(response => {
                if (response.data.success) {
                    console.log('Note updated successfully');
                    const updatedNotes = notes.map(note => {
                        if (note.id === activeNoteId) {
                            return { ...note, content: currentContent, title, description: trimmedDescription };
                        }
                        return note;
                    });
                    setNotes(updatedNotes);
                    applyProjectFilter(updatedNotes);
                } else {
                    console.error('Error updating note:', response.data.message);
                }
            })
            .catch(error => {
                console.error('Error updating note:', error);
            });
    };

    const applyProjectFilter = (notesList) => {
        const filtered = selectedProject
            ? notesList.filter(note => note.project_id === parseInt(selectedProject))
            : notesList;
        setFilteredNotes(filtered);
    };

    const handleProjectChange = (e) => {
        setSelectedProject(e.target.value);
        applyProjectFilter(notes);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        if (e.key === 'Enter') {
            const filtered = notes.filter(note =>
                note.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                note.description.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredNotes(filtered);
        }
    };

    return (
        <section className="horizontal_container fullwidth" id="noteapp">
            <aside>
                <section className="vertical_container">
                    <header className="header bg-light lighter bg-gradient with-border-bottom">
                        <button className="btn btn-success pull-right btn-sm" id="new-note" onClick={handleNewNote}>
                            <i className="fa fa-plus"></i> Новая заметка
                        </button>
                        <select
                            className="btn btn-sm btn-default pull-right m-r-sm"
                            value={selectedProject}
                            onChange={handleProjectChange}
                        >
                            <option value="">Все проекты</option>
                            {projects.map(project => (
                                <option key={project.id} value={project.id}>{project.name}</option>
                            ))}
                        </select>
                        <p class="h4 pull-left">Заметки</p>
                    </header>
                    <section className="bg-light lighter">
                        <section className="horizontal_container fullwidth">
                            <aside>
                                <section className="vertical_container with-border-bottom">
                                    <section className="paper" id="note-detail">
                                        {activeNoteId && (
                                            <>
                                                <textarea
                                                    type="text"
                                                    className="form-control scrollable"
                                                    placeholder="Текст заметки..."
                                                    value={currentContent}
                                                    onChange={handleContentChange}
                                                />
                                                <button className="btn btn-primary m-t-sm" onClick={saveNoteChanges}>
                                                    Save
                                                </button>
                                            </>
                                        )}
                                    </section>
                                </section>
                            </aside>
                        </section>
                    </section>
                </section>
            </aside>
            <NotesList
                notes={filteredNotes}
                activeNoteId={activeNoteId}
                onNoteClick={handleNoteClick}
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
                onSearchSubmit={handleSearchSubmit}
            />
        </section>
    );
}

export default Notes;
