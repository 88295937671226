import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { fetchTasksFromDomain, formatDate, getTaskStyle } from './taskFetcher'; // Импортируем функции

function External() {
    const [domains, setDomains] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState('');
    const [tasks, setTasks] = useState([]);
    const [activeTab, setActiveTab] = useState('messages-1');
    const [newTask, setNewTask] = useState({ title: '', description: '', dueDate: '', projectId: '' });
    const [showDetails, setShowDetails] = useState({});
    const [editTask, setEditTask] = useState({}); // Состояние для редактирования задач

    useEffect(() => {
        axios.get('/api/domains/read.php')
            .then(response => {
                setDomains(response.data);
            })
            .catch(error => {
                console.error('Ошибка при получении доменов:', error);
            });
    }, []);

    const handleDomainChange = async (e) => {
        const domain = e.target.value;
        setSelectedDomain(domain);

        if (domain) {
            try {
                const fetchedTasks = await fetchTasksFromDomain(domain);
                setTasks(fetchedTasks);
            } catch (error) {
                console.error('Ошибка при загрузке задач:', error);
            }
        }
    };

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const toggleDetails = (taskId) => {
        setShowDetails(prevState => ({
            ...prevState,
            [taskId]: !prevState[taskId]
        }));
    };

    const handleEditClick = (task) => {
        setEditTask({
            id: task.id,
            title: task.title,
            description: task.description,
            dueDate: task.due_date,
        });
    };

    const handleCreateTask = () => {
        if (!newTask.title || !newTask.description || !newTask.dueDate || !newTask.projectId || !selectedDomain) {
            alert('Заполните все поля');
            return;
        }

        const currentDomain = window.location.hostname;
        const currentPseudonym = domains.find(domain => domain.domain === currentDomain)?.pseudonym;

        console.log(`Текущий домен: ${currentDomain}, псевдоним: ${currentPseudonym}`); // Логирование текущего домена и псевдонима

        axios.post(`https://${selectedDomain}/api/external/tasks/create.php`, {
            title: newTask.title,
            description: newTask.description,
            due_date: newTask.dueDate,
            project_id: newTask.projectId,
            source_domain: `${currentPseudonym} (${currentDomain})`
        })
        .then(() => {
            alert('Задача успешно создана');
        })
        .catch(error => {
            console.error('Ошибка при создании задачи на удаленном домене:', error);
        });
    };

    const handleSaveClick = (taskId) => {
        const currentDomain = window.location.hostname;
        const currentPseudonym = domains.find(domain => domain.domain === currentDomain)?.pseudonym;

        axios.post(`https://${selectedDomain}/api/external/tasks/update.php`, {
            id: taskId,
            title: editTask.title,
            description: editTask.description,
            due_date: editTask.dueDate,
            source_domain: `${currentPseudonym} (${currentDomain})`
        })
        .then(() => {
            alert('Изменения успешно сохранены');
            setEditTask({}); // Сбрасываем состояние редактирования
        })
        .catch(error => {
            console.error('Ошибка при сохранении изменений:', error);
        });
    };

    return (
        <>
            <section className="panel">
                <header className="panel-heading bg-light">
                    <ul className="nav nav-tabs pull-right">
                        <li className={activeTab === 'messages-1' ? 'active' : ''}>
                            <a href="#" onClick={() => handleTabClick('messages-1')}>
                                <i className="fa fa-comments text-default"></i> Задачи
                            </a>
                        </li>
                        <li className={activeTab === 'profile-1' ? 'active' : ''}>
                            <a href="#" onClick={() => handleTabClick('profile-1')}>
                                <i className="fa fa-user text-default"></i> Профиль
                            </a>
                        </li>
                        <li className={activeTab === 'settings-1' ? 'active' : ''}>
                            <a href="#" onClick={() => handleTabClick('settings-1')}>
                                <i className="fa fa-cog text-default"></i> Настройки
                            </a>
                        </li>
                    </ul>
                    <div>
                        <select className="btn btn-sm btn-default m-r-sm" value={selectedDomain} onChange={handleDomainChange}>
                            <option value="">Выберите домен</option>
                            {domains.map(domain => (
                                <option key={domain.id} value={domain.domain}>
                                    {domain.pseudonym} | {domain.domain}
                                </option>
                            ))}
                        </select>
                    </div>
                </header>
                <div className="panel-body">
                    <div className="tab-content">
                        <div className={`tab-pane ${activeTab === 'messages-1' ? 'active' : ''}`} id="messages-1">
                            <ul>
                                {tasks.map(task => {
                                    const currentDomain = window.location.hostname;
                                    const isFromCurrentDomain = task.source_domain && task.source_domain.includes(currentDomain);

                                    return (
                                        <li key={task.id} style={getTaskStyle(task)}>
                                            {editTask.id === task.id ? (
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={editTask.title}
                                                        onChange={(e) => setEditTask({ ...editTask, title: e.target.value })}
                                                    />
                                                    <textarea
                                                        value={editTask.description}
                                                        onChange={(e) => setEditTask({ ...editTask, description: e.target.value })}
                                                    ></textarea>
                                                    <input
                                                        type="date"
                                                        value={editTask.dueDate}
                                                        onChange={(e) => setEditTask({ ...editTask, dueDate: e.target.value })}
                                                    />
                                                    <button onClick={() => handleSaveClick(task.id)}>Сохранить</button>
                                                </div>
                                            ) : (
                                                <>
                                                    {task.title}
                                                    (поставлена {formatDate(task.created_at)}
                                                    {task.due_date && `, срок выполнения - ${formatDate(task.due_date)}`}) 
                                                    | {task.project_name || 'Без проекта'} — {task.status === 1 ? 'Выполнена' : 'Не выполнена'}
                                                    {task.pinned === 1 && (
                                                        <i className="fa fa-thumb-tack" style={{ marginLeft: '10px', color: 'red' }}></i>
                                                    )}
                                                    {isFromCurrentDomain && (
                                                        <>
                                                            <span className="label bg-primary" style={{ marginLeft: '10px' }}>
                                                                Эта задача была поставлена вами
                                                            </span>
                                                            {/* Кнопка для редактирования */}
                                                            <i
                                                                className="fa fa-pencil"
                                                                style={{ marginLeft: '10px', cursor: 'pointer' }}
                                                                onClick={() => handleEditClick(task)}
                                                            ></i>
                                                        </>
                                                    )}
                                                    {/* Кнопка для отображения деталей */}
                                                    <i
                                                        className="fa fa-question-circle"
                                                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                                                        onClick={() => toggleDetails(task.id)}
                                                    ></i>
                                                    {showDetails[task.id] && (
                                                        <div className="task-details">
                                                            <p><strong>Описание:</strong> {task.description}</p>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className={`tab-pane ${activeTab === 'profile-1' ? 'active' : ''}`} id="profile-1">
                            <div>
                                <h3>Создать задачу</h3>
                                <input
                                    type="text"
                                    placeholder="Название задачи"
                                    value={newTask.title}
                                    onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                                />
                                <textarea
                                    placeholder="Описание"
                                    value={newTask.description}
                                    onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                                ></textarea>
                                <input
                                    type="date"
                                    value={newTask.dueDate}
                                    onChange={(e) => setNewTask({ ...newTask, dueDate: e.target.value })}
                                />
                                <input
                                    type="number"
                                    placeholder="ID проекта"
                                    value={newTask.projectId}
                                    onChange={(e) => setNewTask({ ...newTask, projectId: e.target.value })}
                                />
                                <button className="btn btn-primary" onClick={handleCreateTask}>Создать задачу</button>
                            </div>
                        </div>
                        <div className={`tab-pane ${activeTab === 'settings-1' ? 'active' : ''}`} id="settings-1">Settings content</div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default External;
